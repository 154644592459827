<template>
  <b-modal
    @hide="$emit('close')"
    title="Configure Memfault"
    visible="visible"
    @shown="touchAllFields"
  >
    <b-form>
      <b-form-group
        size="sm"
        id="input-group-1"
        label="Integration name"
        label-for="input-1"
        description="Your name for this integration"
      >
        <b-form-input
          size="sm"
          id="input-2"
          v-model="$v.value.localName.$model"
          type="text"
          :state="!$v.value.localName.$error"
        >
        </b-form-input>
        <div class="form-error" v-if="$v.value.localName.$error">
          Name is required
        </div>
      </b-form-group>

      <b-form-group
        label="Namespace"
        description="Requests must be configured to use this namespace"
      >
        <b-form-input
          v-model="$v.value.localConfig.namespace.$model"
          placeholder="Enter Namespace"
          size="sm"
          :state="!$v.value.localConfig.namespace.$error"
        ></b-form-input>
        <div class="form-error" v-if="$v.value.localConfig.namespace.$error">
          {{ $v.value.localConfig.namespace.$errors[0].$message }}
        </div>
      </b-form-group>
      <b-form-group
        label="Memfault Project Key"
        description="Obtained from your Memfault project settings"
      >
        <b-form-input
          v-model="$v.value.localConfig.secret_memfault_key.$model"
          placeholder="Enter Key"
          size="sm"
          :state="!$v.value.localConfig.secret_memfault_key.$error"
        ></b-form-input>
        <div
          class="form-error"
          v-if="$v.value.localConfig.secret_memfault_key.$error"
        >
          Project key is required
        </div>
      </b-form-group>
    </b-form>
    <div v-if="!routesValid" class="alert alert-danger mt-3">
      <span
        >Invalid configuration. Namespace already claimed by other
        integrations.</span
      >
    </div>

    <template #modal-footer>
      <b-button variant="secondary" @click="$emit('close')">Cancel</b-button>
      <b-button
        variant="primary"
        :disabled="$v.value.$errors.length > 0 || !routesValid"
        @click="saveConfig"
        >Save</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers, url } from "@vuelidate/validators";
import {
  uniqueNamespace,
  checkValidRoutes,
  NAMESPACE_REGEX,
  NAMESPACE_REGEX_MESSAGE
} from "@/utils/validators";
import { ro } from "date-fns/locale";

export default {
  props: {
    config: {
      type: Object,
      required: true
    },
    integration: {
      type: Object,
      required: true
    },
    installedIntegrations: {
      type: Array,
      required: true
    },
    networkConfig: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      localConfig: { ...this.config }, // Local copy of config to edit
      localName: this.integration.name,
      visible: true, // Modal is visible by default
      routesValid: true
    };
  },

  validations() {
    return {
      localName: {
        required,
        uniqueName: helpers.withMessage(
          "Unique name required",
          value =>
            !this.installedIntegrations.find(
              i =>
                i.name === value && i.config_id !== this.integration.config_id
            )
        )
      },
      localConfig: {
        namespace: {
          required,
          maxLength: maxLength(20),
          alphaNumeric: helpers.withMessage(NAMESPACE_REGEX_MESSAGE, value =>
            NAMESPACE_REGEX.test(value)
          )
        },
        secret_memfault_key: {
          required
        }
      }
    };
  },
  created() {
    this.$log.debug("Memfault config", this.config);
    this.$v = useVuelidate(this.validations, this);
    this.integration.url = "https://network-memfault.integrations.blecon.net";
  },

  watch: {
    localConfig: {
      deep: true,
      handler(newVal, OldVal) {
        this.routesValid = checkValidRoutes(
          this.getNewRoutes(),
          this.networkConfig.routes
        );
      }
    }
  },

  methods: {
    getNewRoutes() {
      var headers = [];
      var routes = [];
      if (this.localConfig.namespace && this.localConfig.secret_memfault_key) {
        routes.push({
          filter_events: "device.*." + this.localConfig.namespace + ".*",
          headers: headers,
          url: this.integration.url
        });
      }
      return routes;
    },

    touchAllFields() {
      this.$v.value.$touch(); // Mark all fields as "touched"
    },

    saveConfig() {
      this.$log.debug("Saving config", this.localConfig);
      this.$v.value.$touch(); // Mark all fields as "touched"
      var routes = this.getNewRoutes();
      this.$log.debug("Saving config:: ", routes);
      this.$emit("save", this.localConfig, this.localName, routes); // Emit the updated config back to the parent
    }
  }
};
</script>
