<template>
  <b-container>
    <br /><br /><br />
    <p class="text-center"></p>
    <h2>Create a Blecon Account</h2>

    <p class="consolenotify"></p>
    <p>
      <b-form @submit="onSubmit" @reset="onReset">
        <b-form-group
          size="sm"
          id="input-group-1"
          label="Your company name"
          label-for="name"
          description="This will be your account name"
        >
          <b-form-input
            size="sm"
            id="name"
            v-model="$v.value.form.name.$model"
            :state="nameState"
            type="text"
          >
          </b-form-input>

          <div class="form-error" v-if="$v.value.form.name.$error">
            {{ $v.value.form.name.$errors[0].$message }}
          </div>
        </b-form-group>

        <b-form-group
          size="sm"
          id="input-group-2"
          label="Your company website"
          label-for="website"
        >
          <b-form-input
            size="sm"
            id="website"
            v-model="$v.value.form.website.$model"
            :state="websiteState"
            type="text"
          >
          </b-form-input>

          <div class="form-error" v-if="$v.value.form.website.$error">
            {{ $v.value.form.website.$errors[0].$message }}
          </div>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
            id="checkbox-1"
            v-model="$v.value.form.checked.$model"
            name="checkbox-1"
            :state="checkedState"
            value="true"
            unchecked-value="false"
          >
            I agree to the Blecon
            <b-link href="https://www.blecon.net/terms" target="_new"
              >Terms of Service</b-link
            >
          </b-form-checkbox>
          <div class="form-error" v-if="$v.value.form.checked.$error">
            You must agree to the terms of service
          </div>
        </b-form-group>

        <b-button
          type="submit"
          :disabled="$v.value.$errors.length > 0"
          variant="primary"
        >
          Create Account</b-button
        >
      </b-form>
    </p>
  </b-container>
</template>
<script>
import { bus } from "../main";
import router from "@/router";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  minLength,
  helpers,
  url
} from "@vuelidate/validators";

export default {
  validations() {
    return {
      form: {
        name: { required, minLength: minLength(3), maxLength: maxLength(100) },
        website: { url },
        checked: {
          mustBeTrue: value => value === "true"
        }
      }
    };
  },

  data() {
    return {
      form: {
        name: "",
        website: "",
        checked: "false"
      }
    };
  },

  created() {
    this.$v = useVuelidate(this.validations, this);
  },

  computed: {
    // Computed property for Name field state
    nameState() {
      const field = this.$v.value.form.name;
      if (!field.$dirty) return null; // Neutral state before interaction
      return !field.$invalid; // true if valid, false if invalid
    },

    websiteState() {
      const field = this.$v.value.form.website;
      if (!field.$dirty) return null; // Neutral state before interaction
      return !field.$invalid; // true if valid, false if invalid
    },

    checkedState() {
      const field = this.$v.value.form.checked;
      if (!field.$dirty) return null; // Neutral state before interaction
      return !field.$invalid; // true if valid, false if invalid
    }
  },
  methods: {
    showModal(event) {
      this.$root.$emit("bv::show::modal", "modal-acct");
    },
    async onSubmit(event) {
      event.preventDefault();
      this.$v.value.$touch();
      if (this.$v.value.$errors.length > 0) {
        // Handle form submission errors
        return;
      }

      this.$axios
        .post("/accounts", this.form)

        .then(response => {
          this.$notify({
            title: "Account created. Welcome to Blecon!",
            type: "success"
          });

          bus.$emit("reload-accounts");
          bus.$on("accounts-reloaded", data => {
            router.push({
              name: "root"
            });
          });
        })
        .catch(err => {
          if (err.response != undefined) {
            this.$notify({
              title: "Error " + (err.response.data.message || ""),
              type: "error"
            });
          }
        });
      //Reload from API (keeps state sane)
    },
    onReset(event) {
      this.$root.$emit("bv::hide::modal", "modal-1");

      event.preventDefault();
      // Reset our form values to what we last got from the API
      this.form = {};
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>
