import Vue from "vue";
import Vuex from "vuex";
import createPersist from "vuex-localstorage";
import { bus } from "../main";
import router from "@/router";

Vue.use(Vuex);

// Modules

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  state: {
    spin: false,
    pageload: false,
    accountName: undefined,
    accountid: undefined,
    accountList: [],
    accountLevel: undefined,
    redirectPath: "/",
    redirectParams: {},
    modelsEnabled: undefined,
    gwEnabled: undefined,
    gfwEnabled: undefined,
    user: {}
  },
  getters: {
    accountList(state) {
      return state.accountList;
    },
    account(state) {
      return state.accountid;
    },
    user(state) {
      return state.user;
    }
  },
  mutations: {
    resetState(state) {
      state.accountid = undefined;
      state.accountName = undefined;
      state.accountLevel = undefined;
      state.accountList = [];
      state.modelsEnabled = undefined;
      state.gwEnabled = undefined;
      state.gfwEnabled = undefined;
      state.spin = false;
    },
    redirectState(state, payload) {
      state.redirectPath = payload.path;
      state.redirectParams = payload.params;
    },
    timer(state, payload) {
      state.timer = payload;
    },
    selectedAccount(state, payload) {
      state.accountid = payload.id;
      state.accountName = payload.name;
      state.accountLevel = payload.level;
      state.modelsEnabled = payload.modelsEnabled;
      state.gwEnabled = payload.gwEnabled;
      state.gfwEnabled = payload.gfwEnabled;

      if (router.currentRoute.name != "root") {
        if (router.currentRoute.query.select_account) {
          router
            .push({
              name: "root"
            })
            .catch(err => {});
        }
      }

      bus.$emit("switched-account", "Switching account");
    },
    user(state, payload) {
      state.user = payload.user;
    },
    accountList(state, payload) {
      state.accountList = payload.accountList;
    },
    initialLoad(state) {
      state.initialLoad = true;
    },
    loadingFinished(state) {
      state.initialLoad = false;
    },
    spin(state) {
      state.spin = true;
    },
    nospin(state) {
      state.spin = false;
    },
    pageLoad(state) {
      state.pageload = true;
    },
    pageLoadFinished(state) {
      state.pageload = false;
    }
  },
  modules: {},
  strict: debug,

  plugins: [
    createPersist({
      namespace: "blecon",
      initialState: {},
      // ONE_WEEK
      expires: 7 * 24 * 60 * 60 * 1e3
    })
  ]
});

export default store;
