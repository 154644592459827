<template>
  <div>
    <b-modal
      id="modal-fullevent"
      :title="'Event Preview'"
      hide-footer
      @close="closeModal"
      @reset="closeModal"
      @hidden="closeModal"
    >
      <pre v-html="highlightedJson"></pre>
    </b-modal>
    <b-card no-body header-tag="header" class="mt-3" id="filters">
      <template #header>
        <h3 class="mb-0">Recent Events</h3>
        <span class="text-muted" v-if="show_header">
          <br />
          Configure a
          <router-link :to="{ name: 'net_routes' }">route</router-link> to
          receive events in real-time to a URL. Questions?
          <a href="https://support.blecon.net" target="support"
            >Ask for help.</a
          >
        </span>
      </template>

      <b-card-text class="mt-0 pt-3 pl-3 pr-3 pb-3">
        <b-container fluid>
          <b-row class="mb-3">
            <!-- Device Connection Status Filter -->
            <b-col md="5">
              <b-form-group label="Device ID">
                <b-form-input
                  v-model="filters.device_id"
                  placeholder="Filter by Device ID"
                  class="mr-3 pr-3"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Event Type">
                <b-form-input
                  v-model="filters.event_type"
                  placeholder="Filter by Event Type"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="mb-3">
                <button
                  @click.prevent="getResults"
                  class="btn btn-primary mr-3 "
                >
                  Refresh
                </button>
                <button @click.prevent="resetFilters" class="btn btn-secondary">
                  Clear Filters
                </button>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-table
          striped
          responsive
          hover
          small
          stacked="sm"
          :items="results"
          :fields="fields"
          :no-local-sorting="true"
          :current-page="currentPage"
          :busy="isLoading"
          @sort-changed="onSortChanged"
        >
          <template v-slot:cell(timestamp)="data">
            <div style=" white-space: nowrap; width: 10em;">
              {{ formatDate(data.item.timestamp) }}
            </div>
          </template>
          <template v-slot:cell(event_type)="data">
            <div style="max-width: 20em; font-size: 0.9em; font-weight: bold">
              <a
                href="#"
                :class="
                  data.item.type == 'ERROR' ? 'text-danger' : 'text-success'
                "
                @click.prevent="selectEvent(data.item)"
              >
                {{ data.item.event_type }}</a
              >
              <a
                v-if="data.item.event_type"
                href="#filters"
                @click.prevent="
                  filters.event_type = data.item.event_type;
                  getResults();
                "
              >
                <i title="Filter by event type" class="ml-2 bx bx-search"></i>
              </a>
            </div>
          </template>
          <template v-slot:cell(device_id)="data">
            <div
              style="display: grid; grid-template-columns: auto; min-width: 160px;"
            >
              <router-link
                v-if="data.item.device_id"
                :to="{
                  name: 'viewdevice',
                  params: { devId: data.item.device_id }
                }"
                ><code>{{ data.item.device_id }}</code></router-link
              >

              <a
                v-if="data.item.device_id"
                href="#filters"
                @click="
                  filters.device_id = data.item.device_id;
                  getResults();
                "
              >
                <i title="Filter by Device ID" class="ml-2 bx bx-search"></i>
              </a>
            </div>
          </template>

          <template v-slot:cell(text)="data">
            <div style="font-size: 0.9em;  ">
              <span
                v-if="
                  data &&
                    data.item &&
                    data.item.full_event &&
                    data.item.full_event.type == 'network.device_spotted'
                "
              >
                <span
                  v-if="
                    data.item.full_event &&
                      data.item.full_event &&
                      data.item.full_event.data &&
                      data.item.full_event.data.location
                  "
                >
                  Device spotted at
                  <a
                    :href="
                      'https://maps.google.com/?q=' +
                        data.item.full_event.data.location
                    "
                    target="_new"
                    >{{ data.item.full_event.data.location }}</a
                  >
                </span>
              </span>
              <span v-else>
                {{ data.item.text }}
              </span>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="this.total"
          @change.prevent="getResults"
        />
        <p v-if="loads < 1" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
        </p>
        <p class="text-center" v-if="!results.length && loads > 0">
          Waiting for events
        </p>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";
hljs.registerLanguage("json", json);

import "highlight.js/styles/default.css"; // or choose another style

import {
  format,
  formatDistance,
  formatRelative,
  lastDayOfDecade,
  subDays
} from "date-fns";
import store from "@/store";

export default {
  name: "EventsExplorer",

  props: {
    network_id_filter: {
      type: String
    },
    device_id_filter: {
      type: String
    },
    show_header: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      time1: new Date().toISOString().split("T")[0],
      results: [],
      loads: 0,
      timer: undefined,
      selectedEvent: null,
      loopEnabled: true,
      fields: [
        {
          key: "timestamp",
          label: "Time (UTC)",
          sortable: true,
          formatter: "formatDate"
        },
        { key: "device_id", label: "Device", sortable: false },

        { key: "event_type", label: "Event", sortable: false },
        { key: "text", label: "Details", sortable: false }
      ],

      filters: {
        device_id: null
      },
      dateFilters: [
        {
          field: "created",
          start: null,
          end: null
        },
        {
          field: "last_seen",
          start: null,
          end: null
        },
        {
          field: "last_updated",
          start: null,
          end: null
        }
      ],

      currentPage: 1,
      total: 0,
      perPage: 30,
      isLoading: false,
      cursor: undefined,
      deviceCount: 0,
      sort: { field: "timestamp", order: "desc" }
    };
  },
  computed: {
    highlightedJson() {
      if (!this.selectedEvent) return "";
      const jsonString = JSON.parse(this.selectedEvent);
      const parsedJson = JSON.stringify(jsonString, null, 2);

      return hljs.highlight(parsedJson, { language: "json" }).value;
    }
  },
  methods: {
    startTimeout() {
      this.loopEnabled = true;
    },
    stopTimeout() {
      this.loopEnabled = false;
    },

    resetFilters() {
      this.filters = {
        device_id: null,
        event_type: null
      };
      this.dateFilters = [
        {
          field: "created",
          start: null,
          end: null
        },
        {
          field: "last_seen",
          start: null,
          end: null
        },
        {
          field: "last_updated",
          start: null,
          end: null
        }
      ];
      this.getResults();
    },

    selectEvent(event) {
      this.selectedEvent = event.full_event;
      if (this.selectedEvent != undefined) {
        document.body.style.overflow = "hidden"; // Disable scrolling

        this.$root.$emit("bv::show::modal", "modal-fullevent");
      }
    },
    closeModal() {
      this.selectedEvent = null;
      document.body.style.overflow = "auto"; // Enable scrolling
    },

    formatDate(value) {
      const formattedDate = format(new Date(value), "yyyy-MM-dd HH:mm:ss");
      return formattedDate;
    },

    async getResults() {
      this.isLoading = true;
      var accessToken = await this.$auth.getTokenSilently();
      //store.commit("spin");

      const mustClauses = [];
      if (this.network_id_filter) {
        mustClauses.push({
          field: "network_id",
          value: this.network_id_filter
        });
      }

      if (this.filters.device_id) {
        mustClauses.push({ field: "device_id", value: this.filters.device_id });
      }

      if (this.filters.event_type) {
        mustClauses.push({
          field: "event_type",
          value: this.filters.event_type
        });
      }

      const searchBody = {
        sort: this.sort,
        pagination: {
          page_size: this.perPage,
          page: this.currentPage
        },
        fields: mustClauses,
        date_ranges: this.dateFilters
      };

      this.$axios
        .post(
          "/v1/accounts/" + store.state.accountid + "/log-explorer",
          searchBody,
          { nospin: true }
        )
        .then(response => {
          this.results = response.data.results;
          this.total = response.data.total;
          this.currentPage = response.data.page;
          store.commit("nospin");
        });
      this.loads++;

      this.isLoading = false;
    },

    onSortChanged({ sortBy, sortDesc }) {
      var sortType = "asc";
      if (sortDesc == true) sortType = "desc";
      this.sort = { field: sortBy, order: sortType };
      this.getResults();
    }
  },
  mounted() {
    // Load initial data when the component is mounted
    this.$log.debug("Device ID filter", this.device_id_filter);
    // if query param for device_id
    if (this.device_id_filter) {
      this.filters.device_id = this.device_id_filter;
    }
    this.getResults();
  },

  beforeDestroy() {
    // Component is hidden; clear the timeout
    this.stopTimeout();
  },
  activated() {
    // Component is visible again; restart the timeout if needed
    //this.startTimeout();
  },

  watch: {
    // Load data whenever the page changes
    currentPage() {
      this.getResults();
    }
  }
};
</script>
