<template>
  <b-modal
    @hide="$emit('close')"
    title="Configure Webhook"
    visible="visible"
    size="lg"
    @shown="touchAllFields"
  >
    <b-form>
      <b-form-group
        size="sm"
        id="input-group-1"
        label="Integration name"
        label-for="input-1"
        description="Your name for this integration"
      >
        <b-form-input
          size="sm"
          id="input-2"
          v-model="$v.value.localName.$model"
          type="text"
          :state="!$v.value.localName.$error"
        >
        </b-form-input>
        <div class="form-error" v-if="$v.value.localName.$error">
          Name is required
        </div>
      </b-form-group>

      <b-form-group label="Select Events">
        <b-form-checkbox-group
          v-model="$v.value.selectedOptions.$model"
          :options="options"
          name="options"
          :state="!$v.value.selectedOptions.$error"
          size="sm"
        ></b-form-checkbox-group>

        <b-form-invalid-feedback v-if="$v.value.selectedOptions.$error">
          At least one option must be selected.
        </b-form-invalid-feedback>
      </b-form-group>
      <p>
        For more information about events and event formats, see
        <a
          href="https://docs.blecon.net/learn-blecon/blecon-networks/blecon-events"
          target="docs"
          >Blecon Event Types</a
        >
      </p>
      <!-- Conditional Input for Option A -->
      <b-form-group
        description="Enter namespace for requests, or * for all"
        label="Device request namespace"
        default="*"
        v-if="selectedOptions.includes('NAMESPACE')"
      >
        <b-form-input
          v-model="$v.value.localConfig.capture_device_namespace.$model"
          placeholder="namespace"
          :state="!$v.value.localConfig.capture_device_namespace.$error"
          size="sm"
        ></b-form-input>

        <div
          class="form-error"
          v-if="$v.value.localConfig.capture_device_namespace.$error"
        >
          {{
            $v.value.localConfig.capture_device_namespace.$errors[0].$message
          }}
        </div>
      </b-form-group>

      <b-form-group label="URL" description="Where to send events">
        <b-form-input
          v-model="$v.value.localConfig.url.$model"
          placeholder="Enter URL"
          size="sm"
          :state="!$v.value.localConfig.url.$error"
        ></b-form-input>
        <div class="form-error" v-if="$v.value.localConfig.url.$error">
          URL is required
        </div>
      </b-form-group>
      <p>
        Optional Headers
      </p>

      <b-container>
        <b-row class="my-1">
          <b-col sm="1">
            Name:
          </b-col>
          <b-col>
            <b-form-input
              id="headers-key-1"
              size="sm"
              v-model="$v.value.localConfig.headers_key_1.$model"
              placeholder="X-Header-Example"
            ></b-form-input>
          </b-col>
          <b-col sm="1">
            Value:
          </b-col>
          <b-col>
            <b-form-input
              id="headers-val-1"
              v-model="$v.value.localConfig.headers_val_1.$model"
              size="sm"
              placeholder="header-value"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col sm="1">
            Name:
          </b-col>
          <b-col>
            <b-form-input
              id="headers-key-2"
              v-model="$v.value.localConfig.headers_key_2.$model"
              size="sm"
              placeholder="X-Header-Example"
            ></b-form-input>
          </b-col>
          <b-col sm="1">
            Value:
          </b-col>
          <b-col>
            <b-form-input
              id="headers-value-2"
              v-model="$v.value.localConfig.headers_val_2.$model"
              size="sm"
              placeholder="header-value"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col sm="1">
            Name:
          </b-col>
          <b-col>
            <b-form-input
              id="headers-key-3"
              v-model="$v.value.localConfig.headers_key_3.$model"
              size="sm"
              placeholder="X-Header-Example"
            ></b-form-input>
          </b-col>
          <b-col sm="1">
            Value:
          </b-col>
          <b-col>
            <b-form-input
              id="headers-val-3"
              v-model="$v.value.localConfig.headers_val_3.$model"
              size="sm"
              placeholder="header-value"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-container>
    </b-form>

    <div v-if="!routesValid" class="alert alert-danger mt-3">
      <span
        >Invalid configuration. Some of these events are already claimed by
        other integrations.</span
      >
    </div>

    <template #modal-footer>
      <b-button variant="secondary" @click="$emit('close')">Cancel</b-button>
      <b-button
        variant="primary"
        :disabled="$v.value.$errors.length > 0 || !routesValid"
        @click="saveConfig"
        >Save</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers, url } from "@vuelidate/validators";
import { checkValidRoutes, NAMESPACE_REGEX } from "@/utils/validators";

export default {
  props: {
    config: {
      type: Object,
      required: true
    },
    integration: {
      type: Object,
      required: true
    },
    installedIntegrations: {
      type: Array,
      required: true
    },
    networkConfig: {
      type: Object,
      required: true
    }
  },

  data() {
    this.$log.debug("Starting webhook config", this.config, this.integration);
    return {
      localConfig: this.createLocalConfig(),
      routesValid: true,
      localName: this.integration.name,
      visible: true, // Modal is visible by default
      selectedOptions: [], // Stores the selected options (multi-select)
      options: [
        { value: "NAMESPACE", text: "Device requests and messages" },
        { value: "NETWORK_EVENTS", text: "network.* events" }
      ]
    };
  },

  validations() {
    return {
      localName: {
        required,
        uniqueName: helpers.withMessage(
          "Unique name required",
          value =>
            !this.installedIntegrations.find(
              i =>
                i.name === value && i.config_id !== this.integration.config_id
            )
        )
      },

      selectedOptions: {
        atLeastOne: helpers.withMessage(
          "At least one option must be selected",
          value => value && value.length > 0
        )
      },
      localConfig: {
        url: {
          required,
          url
        },

        capture_device_namespace: {
          requiredIfSelected: helpers.withMessage(
            "Variable is required for Option A",
            (value, vm) => {
              return (
                !this.selectedOptions.includes("NAMESPACE") ||
                (value && value.length > 0)
              );
            }
          ),
          maxLength: maxLength(20),
          alphaNumeric: helpers.withMessage(
            "Only alphanumeric characters and - and _ are allowed in namespaces",
            value => NAMESPACE_REGEX.test(value) // Regex to validate single word, alphanumeric only
          )
        },

        capture_device: {},
        capture_network_all: {},
        headers_key_1: {},
        headers_val_1: {},
        headers_key_2: {},
        headers_val_2: {},
        headers_key_3: {},
        headers_val_3: {}
      }
    };
  },
  created() {
    this.$log.debug(
      "Webhook config, integration, and network config",
      this.localConfig,
      this.integration,
      this.networkConfig
    );
    this.$v = useVuelidate(this.validations, this);

    //if localconfig capture_network_all is true, add NETWORK_EVENTS to selectedOptions
    if (this.localConfig.capture_network_all) {
      this.selectedOptions.push("NETWORK_EVENTS");
    }

    // if capture_device is true, add NAMESPACE to selectedOptions
    if (this.localConfig.capture_device) {
      this.selectedOptions.push("NAMESPACE");
    }

    //mark as dirty
  },
  watch: {
    selectedOptions() {
      this.routesValid = checkValidRoutes(
        this.getNewRoutes(),
        this.networkConfig.routes
      );
    },

    localConfig: {
      deep: true,
      handler(newVal, OldVal) {
        this.routesValid = checkValidRoutes(
          this.getNewRoutes(),
          this.networkConfig.routes
        );
      }
    }
  },
  methods: {
    getNewRoutes() {
      //construct headers where both key and value are not empty
      var headers = {};
      if (this.localConfig.headers_key_1 && this.localConfig.headers_val_1) {
        headers[
          this.localConfig.headers_key_1
        ] = this.localConfig.headers_val_1;
      }
      if (this.localConfig.headers_key_2 && this.localConfig.headers_val_2) {
        headers[
          this.localConfig.headers_key_2
        ] = this.localConfig.headers_val_2;
      }
      if (this.localConfig.headers_key_3 && this.localConfig.headers_val_3) {
        headers[
          this.localConfig.headers_key_3
        ] = this.localConfig.headers_val_3;
      }

      var url = this.localConfig.url;

      this.$log.debug("Headers", headers);

      var routes = [];
      //if there is the namespace option, add a route

      if (this.selectedOptions.includes("NAMESPACE")) {
        routes.push({
          url: url,
          filter_events:
            "device.*." + this.localConfig.capture_device_namespace + ".*",
          headers: headers
        });
      }

      //if there is the network events option, add a route
      if (this.selectedOptions.includes("NETWORK_EVENTS")) {
        routes.push({
          url: url,
          filter_events: "network.*",
          headers: headers
        });
      }
      return routes;
    },

    createLocalConfig() {
      // Define defaults
      const defaults = {
        capture_device_namespace: "*"
      };
      // Merge the defaults with the provided config
      return { ...defaults, ...this.config };
    },

    touchAllFields() {
      this.$v.value.$touch(); // Mark all fields as "touched"
    },

    saveConfig() {
      //if NETWORK_EVENTS is selected, set capture_network_all to true
      if (this.selectedOptions.includes("NETWORK_EVENTS")) {
        this.localConfig.capture_network_all = true;
      } else {
        this.localConfig.capture_network_all = false;
      }

      //if NAMESPACE is selected, set capture_device to true
      if (this.selectedOptions.includes("NAMESPACE")) {
        this.localConfig.capture_device = true;
      } else {
        this.localConfig.capture_device = false;
      }

      this.$log.debug("Saving config", this.localConfig);

      var routes = this.getNewRoutes();
      var validRoutes = checkValidRoutes(routes, this.networkConfig.routes);

      this.$log.debug("Routes: ", routes, validRoutes);

      this.$v.value.$touch(); // Mark all fields as "touched"

      this.$emit("save", this.localConfig, this.localName, routes); // Emit the updated config back to the parent
    }
  }
};
</script>
