<template>
  <div>
    <EventsExplorer
      :show_header="true"
      :network_id_filter="this.$route.params.networkId"
    ></EventsExplorer>
  </div>
</template>

<script>
import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";
hljs.registerLanguage("json", json);

import "highlight.js/styles/default.css"; // or choose another style
import EventsExplorer from "@/components/EventsExplorer.vue";

import {
  format,
  formatDistance,
  formatRelative,
  lastDayOfDecade,
  subDays
} from "date-fns";
import store from "@/store";

export default {
  components: {
    EventsExplorer
  }
};
</script>
